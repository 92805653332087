import http from "@/plugins/axios";

export default {
  /**
   * 
   * @param {Object} params 
   * @returns 
   */
   all(payload) {
    let url = "v2/snapshots?"
    Object.entries(payload).forEach((value, index) => {
      if (index == 0) {
        url += value[0] + "=" + value[1];
      } else {
        url += "&" + value[0] + "=" + value[1];
      }
    });
    return http.api.get(url);
  },
  single(id, jsonRedirect = true){
    return http.api.get("v2/asset_snapshot/" + id + "/content?json_redirect=" + jsonRedirect);
  },  
}