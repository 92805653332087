<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-toolbar dark dense color="primary" class="rounded-0" style="z-index: 1">
        <v-toolbar-title>Escolha um e-mail</v-toolbar-title>
        <v-text-field
          flat
          dense
          hide-details
          solo-inverted
          class="ml-10"
          placeholder="Pesquisar"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div style="height: calc(100vh - 48px); overflow-y: auto">
        <v-row class="ma-0">
          <v-col cols="12" v-if="loading">
            <div class="d-flex align-center justify-center qj-loading">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="70"
              ></v-progress-circular>
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="4"
            md="3"
            xl="2"
            v-else-if="emails.length"
            v-for="email in emailsFiltered"
            :key="email.id"
          >
            <v-card>
              <v-hover v-slot="{ hover }">
                <v-img height="150" position="left top" :src="email.previewImage">
                  <v-fade-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out v-card--reveal justify-center align-center white--text"
                      style="height: 100%; background-color: rgba(0, 0, 0, 0.3)"
                    >
                      <v-btn color="white" :href="email.previewUrl" target="_blank">
                        Visualizar
                      </v-btn>
                    </div>
                  </v-fade-transition>
                </v-img>
              </v-hover>
              <v-card-title>{{ email.title }}</v-card-title>
              <v-card-subtitle>
                {{ email.name }}
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="exportHtml(email.emailId)"
                  >Importar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" class="text-center" v-else>Nenhum e-mail encontrado</v-col>
        </v-row>
      </div>
    </v-card>
    <v-overlay :value="loadingExport">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import emails from "@/services/emails";

export default {
  props: ["dialog"],
  data: () => ({
    loading: false,
    loadingExport: false,
    dialogm1: "bahamas",
    search: "",
    emails: [],
  }),
  watch: {
    dialog() {
      if (this.dialog) {
        this.loading = true;
        emails
          .emails()
          .then((resp) => (this.emails = resp.data.data))
          .finally(() => (this.loading = false));
      }
    },
  },
  computed: {
    emailsFiltered() {
      return this.emails.filter((item) => {
        if (item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1) return true;
        if (item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) return true;
        return false;
      });
    },
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    exportHtml(id) {
      this.loadingExport = true;
      emails.export(id).then((resp) => {
        this.loadingExport = false;
        this.$emit("html", resp.data);
        this.close();
      });
    },
  },
};
</script>

<style>
.qj-loading {
  width: 100%;
  height: calc(100vh - 168px);
}
</style>
