<template>
  <v-row>
    <v-col cols="12" md="5" lg="4" xl="3">
      <!-- Principal -->
      <v-row>
        <!-- Head -->
        <v-col cols="12" class="pb-0 d-flex">
          <v-btn
            depressed
            color="primary"
            :disabled="!isEdit || !checkCredentials(['admin', 'editor']) || codeHTML.length > limitLengthContent"
            :loading="loadingSave"
            @click="save"
          >
            Salvar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!checkCredentials(['admin', 'editor'])"
            text
            depressed
            color="primary"
            @click="importModel"
            v-if="isImportStripoVisible"
          >
            Importar do Stripo
          </v-btn>
          <v-menu offset-y left v-if="previewData != '{}' || id">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$emit('historic')" v-if="id">
                <v-list-item-icon class="my-auto mr-3">
                  <v-icon>mdi-history</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Histórico</v-list-item-title>
              </v-list-item>

              <v-list-item @click="$emit('snapshot')" v-if="id">
                <v-list-item-icon class="my-auto mr-3">
                  <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Snapshots</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="
                  previewData != '{}' && checkCredentials(['admin', 'editor'])
                "
                @click="dialogParameters = true"
              >
                <v-list-item-icon class="my-auto mr-3">
                  <v-icon>mdi-application-edit-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar Pré-visualizar</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="id && checkCredentials(['admin', 'editor'])"
                @click="dialogDelete = true"
              >
                <v-list-item-icon class="my-auto mr-3">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Remover</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <!-- Body -->
        <v-col cols="12" class="pb-0">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              dense
              outlined
              label="Caminho"
              :rules="[(v) => !!v || 'Informação obrigatória']"
              v-model="path"
              hide-details="auto"
              hint="Use letras minúsculas e sem espaços."
              persistent-hint
              autofocus
              :disabled="!checkCredentials(['admin', 'editor'])"
            ></v-text-field>

            <v-alert
              v-if="asset && asset.path != path"
              type="warning"
            >
              <strong>ATENÇÃO</strong><br>
              Ao alterar o caminho do asset as referências para o caminho anterior deixarão de funcionar.<br>
              Os envios de e-mail ou a criação de outros arquivos podem ser afetados.
            </v-alert>

            <v-alert
              v-if="codeHTML.length > limitLengthContent"
              type="error"
            >
              <strong>ATENÇÃO</strong><br>
              Código está com quantidade de caracteres acima do permitido: {{ codeHTML.length.toLocaleString('pt-BR') }} de 
              {{ limitLengthContent.toLocaleString('pt-BR') }}.<br>
              Favor diminuir para poder salvar!
            </v-alert>

            <div class="d-flex mb-5">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="copy-url mr-1"
                    text
                    icon
                    @click="copyUrl"
                    v-on="on"
                  >
                    <v-icon> mdi-link </v-icon>
                  </v-btn>
                </template>
                <span>Copiar</span>
              </v-tooltip>

              <div class="text--secondary url">
                {{ assetsUrl }}/<strong>{{ path }}</strong>
              </div>
            </div>

            <input
              type="hidden"
              id="urlAssets"
              :value="assetsUrl + '/' + path"
            />

            <v-select
              :disabled="!checkCredentials(['admin', 'editor'])"
              dense
              outlined
              item-text="text"
              item-value="value"
              label="Tipo"
              :items="assetTypes"
              :rules="[(v) => !!v || 'Informação obrigatória']"
              v-model="type"
              hide-details="auto"
            ></v-select>

            <div
              v-if="type == 'JINJA' || type == 'PDF'"
              class="mt-1 ml-2">
              <a
                href="https://jinja.palletsprojects.com/en/3.1.x/templates/"
                target="_blank"
                class="text-decoration-none">
                Documentação do Jinja
                <v-icon class="text-body-1">mdi-open-in-new</v-icon>
              </a>
            </div>

            <v-textarea
              :disabled="!checkCredentials(['admin', 'editor'])"
              dense
              outlined
              no-resize
              hide-details
              rows="5"
              label="Observação"
              v-model="note"
              class="mt-4"
            ></v-textarea>
          </v-form>
        </v-col>
        <v-scroll-x-transition>
          <v-col cols="12" v-if="showConfig">
            <label class="px-3" style="color: rgba(0, 0, 0, 0.6)"
              >Parâmetros
              <v-btn
                icon
                target="_blank"
                href="https://wkhtmltopdf.org/usage/wkhtmltopdf.txt"
                x-small
                title="Documentação do wkhtmltopdf"
              >
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </label>

            <codemirror
              class="codemirrorJSON"
              :options="cmOptionsJSON"
              v-model="codeJSON"
            ></codemirror>
          </v-col>
        </v-scroll-x-transition>
      </v-row>
    </v-col>
    <v-col cols="12" md="7" lg="8" xl="9" class="px-0 pb-0">
      <!-- Condetúdo -->
      <div style="height: calc(100vh - 76px)">
        <v-expand-transition>
          <v-alert
            text
            dense
            type="error"
            class="ma-0 rounded-0"
            v-show="alert"
          >
            É obrigatório preencher o código!
          </v-alert>
        </v-expand-transition>
        <v-slide-x-reverse-transition hide-on-leave>
          <iframe v-if="tab == 0" class="frame" :srcdoc="codeHTML"></iframe>
          <codemirror
            v-if="tab == 1"
            class="codemirrorMain"
            :class="{ alert: alert }"
            :options="cmOptionsHTML"
            v-model="codeHTML"
          ></codemirror>
        </v-slide-x-reverse-transition>
      </div>
    </v-col>
    <DialogParameters
      :variables="variables"
      :editCode="previewData"
      :dialog.sync="dialogParameters"
      @paremeters="openPreview($event)"
    />
    <DialogDelete :dialog.sync="dialogDelete" @remove="edit(false)" />
    <DialogImport
      :dialog.sync="dialogImport"
      @html="
        codeHTML = $event;
        previewData = '{}';
      "
    />
    <v-dialog
      persistent
      v-model="doubleCheckEditDialog"
      width="350"
      @click:outside="doubleChecked = false"
    >
      <v-card>
        <v-card-title class="text-h5 primary dark white--text">
          Editar asset?
        </v-card-title>

        <v-card-text class="pt-4">
          Ao salvar as alterações, referencias a esse asset podem ser perdidas.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="doubleChecked = false"
            >Cancelar</v-btn
          >
          <v-btn color="primary" text @click="doubleChecked = true"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import { bus } from "../../../../main";
import assets from "@/services/assets";
import DialogImport from "../DialogImport";
import DialogDelete from "../DialogDelete";
import DialogParameters from "../DialogParameters";
import enviroment from "@/assets/js/enviroment";

export default {
  props: ["asset"],
  components: {
    DialogImport,
    DialogDelete,
    DialogParameters,
  },
  data: () => ({
    assetsUrl: enviroment.assetsUrl,
    doubleCheckEditDialog: false,
    doubleChecked: null,
    id: undefined,
    valid: true,
    alert: false,
    isEdit: false,
    loadingSave: false,
    dialogImport: false,
    dialogDelete: false,
    dialogParameters: false,
    tab: 0,
    path: "",
    type: "",
    note: "",
    codeHTML: "",
    limitLengthContent: 16777215,
    codeJSON: "{}",
    previewData: "{}",
    variables: [],
    assetTypes: [
      { text: "Jinja", value: "JINJA" },
      { text: "E-mail", value: "EMAIL" },
      { text: "PDF", value: "PDF" },
      { text: "Imagem", value: "IMAGE" },
    ],
    cmOptionsHTML: {
      tabSize: 2,
      line: true,
      lineNumbers: true,
      lineWrapping: true,
      autoCloseTags: true,
      styleActiveLine: true,
      mode: "text/html",
      // extraKeys: { Ctrl: "autocomplete" },
    },
    cmOptionsJSON: {
      tabSize: 2,
      line: true,
      lineNumbers: true,
      styleActiveLine: true,
      mode: "text/json",
    },
  }),
  created() {
    bus.$on("setTab", (tab) => (this.tab = tab));
    bus.$on("preview", () => this.checkPreview());

    if (this.asset) {
      this.id = this.asset.id;
      this.path = this.asset.path;
      this.type = this.asset.type;
      this.note = this.asset.observation;
      this.codeJSON = this.asset.params;
      this.codeHTML = this.asset.content;
      this.previewData = this.asset.preview_params
        ? this.asset.preview_params
        : "{}";
    } else {
      this.tab = 1;
      this.path = this.$route.query.path;
      this.type = this.$route.query.type;
    }
  },
  destroyed() {
    bus.$off("setTab");
    bus.$off("preview");
  },
  computed: {
    ...mapGetters("auth", { me: "getMe" }),
    showConfig() {
      return ["PDF", "Imagem"].indexOf(this.type) > -1 ? true : false;
    },

    /** Indica se o botão "Importar do Stripo" deve estar visível. */
    isImportStripoVisible() {
      return ["JINJA", "EMAIL"].indexOf(this.type) > -1 ? true : false;
    },
  },
  watch: {
    path() {
      this.edtiIsTrue();
    },
    type() {
      this.edtiIsTrue();
    },
    note() {
      this.edtiIsTrue();
    },
    codeHTML() {
      this.edtiIsTrue();
    },
    codeJSON() {
      this.edtiIsTrue();
    },
  },
  methods: {
    ...mapActions("home", ["ActionsReset", "ActionsSetAssets"]),
    ...mapActions("auth", ["checkCredentials"]),
    save() {
      if (!this.validate()) return;
      this.loadingSave = true;
      if (this.id) this.edit(true, false);
      else this.new();
    },

    new() {
      assets
        .new({
          active: true,
          content: this.codeHTML,
          observation: this.note,
          params: this.codeJSON == "{}" ? null : this.codeJSON,
          path: this.path,
          preview_params: this.previewData == "{}" ? null : this.previewData,
          type: this.type,
          user: null,
        })
        .then((resp) => {
          bus.$emit("title", resp.data.path);
          this.$emit("update:asset", resp.data);
          this.path = resp.data.path;
          this.type = resp.data.type;
          this.note = resp.data.observation;
          this.codeJSON = resp.data.params;
          this.codeHTML = resp.data.content;
          this.previewData = resp.data.preview_params
            ? resp.data.preview_params
            : "{}";
          this.id = resp.data.id;
          this.$router.replace({ path: "/asset/" + this.id });
          this.tab = 0;
          this.ActionsReset();
        })
        .catch((error) => {
          let text = "Ocorreu um erro!";
          let errors = error.response.data.errors[0].title.match(/('[^'']+')/g);
          if (errors.length) {
            text = errors[errors.length - 1].replaceAll("'", "");
          }
          bus.$emit("snackbar", text);
        })
        .finally(() => (this.loadingSave = false));
    },
    doubleCheckEdit() {
      return new Promise((resolve, reject) => {
        this.doubleCheckEditDialog = true;
        let interval = setInterval(() => {
          if (this.doubleChecked) {
            clearInterval(interval);
            this.doubleCheckEditDialog = false;
            resolve();
          } else if (this.doubleChecked === false) {
            this.doubleCheckEditDialog = false;
            clearInterval(interval);
            reject();
          }
        }, 100);
      });
    },
    async edit(active = true, toHome = true) {
      try {
        this.doubleChecked = null;
        assets
          .edit(this.id, {
            id: this.id,
            active: active,
            content: this.codeHTML,
            observation: this.note,
            params: this.codeJSON == "{}" ? null : this.codeJSON,
            path: this.path,
            preview_params: this.previewData == "{}" ? null : this.previewData,
            type: this.type,
            user: null,
            user_id: this.me.id,
          })
          .then((resp) => {
            this.$emit("update:asset", resp.data);
            bus.$emit("title", resp.data.path);

            let assets = store.getters["home/getAssets"];
            if (assets.length > 0) {
              let index = assets
                .map((asset) => asset.id)
                .indexOf(parseInt(this.id));
              assets[index] = resp.data;
              this.ActionsSetAssets(assets);
            }
            if (toHome) this.$router.push({ name: "Home" });
            bus.$emit("snackbar", 'Editado com sucesso!');
            bus.$emit("isEdit", (this.isEdit = false));
          })
          .catch((error) => {
            let text = "Ocorreu um erro!";
            let errors =
              error.response.data.errors[0].title.match(/('[^'']+')/g);
            if (errors.length) {
              text = errors[errors.length - 1].replaceAll("'", "");
            }
            bus.$emit("snackbar", text);
          })
          .finally(() => (this.loadingSave = false));
      } catch {
        this.loadingSave = false;
        this.doubleChecked = null;
      }
    },
    edtiIsTrue() {
      let status = false;
      let asset = {
        path: "",
        type: "",
        observation: "",
        content: "",
        params: "",
      };
      if (this.id) asset = this.asset;

      if (this.path != asset.path) status = true;
      else if (this.type != asset.type) status = true;
      else if (this.note != asset.observation) status = true;
      else if (this.codeHTML != asset.content) status = true;
      else if (this.codeJSON != asset.params) status = true;
      bus.$emit("isEdit", (this.isEdit = status));
    },
    importModel() {
      this.dialogImport = true;
    },
    historic() {},
    validate() {
      let isValid = this.$refs.form.validate();
      if (this.codeHTML == "") {
        isValid = false;
        this.alert = true;
      } else this.alert = false;
      return isValid;
    },
    checkPreview() {
      let next = true;
      if (
        this.asset &&
        this.asset.data &&
        this.asset.data.type === "REDIRECT"
      ) {
        window.open(this.asset.data.content, "_blank");
      } else {
        if (this.previewData == "{}") {
          var variables = [];
          var rawVariables = this.codeHTML.match(/({{[^{}]+}})/g);
          if (rawVariables) {
            rawVariables = rawVariables.filter(function (este, i) {
              return rawVariables.indexOf(este) === i;
            });
            rawVariables.forEach((variable) => {
              variables.push(
                variable
                  .replaceAll("{{", "")
                  .replaceAll("}}", "")
                  .replaceAll(" ", "")
              );
            });
            this.variables = variables;
          }
          if (this.variables.length) {
            this.dialogParameters = true;
            next = false;
          }
        }
        if (next) this.openPreview();
      }
    },
    async openPreview(data = "") {
      if (data != "") {
        this.previewData = data;
        if (this.$route.params.id != "new") this.edit(true, false);
      }
      this.preview();
    },
    preview() {
      let asset = this.asset;
      if (asset) {
        asset.content = this.codeHTML;
      } else {
        asset = {
          id: null,
          type: null,
          content: null,
          params: null,
          active: null,
          observation: null,
          preview_params: null,
          path: null,
          created_at: null,
          updated_at: null,
          relationships: null,
          user: null,
        };

        if (this.codeHTML) asset.content = this.codeHTML;
        if (this.previewData) asset.preview_params = this.previewData;
        if (this.path) asset.path = this.path;
        if (this.type) asset.type = this.type;
      }

      var form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", enviroment.assetsUrl + "/preview");

      form.setAttribute("target", "_blank");

      var assetField = document.createElement("input");
      assetField.setAttribute("type", "hidden");
      assetField.setAttribute("name", "asset");
      assetField.setAttribute("value", JSON.stringify(asset));
      form.appendChild(assetField);

      var paramsField = document.createElement("input");
      paramsField.setAttribute("type", "hidden");
      paramsField.setAttribute("name", "params");
      paramsField.setAttribute("value", this.previewData);
      form.appendChild(paramsField);

      document.body.appendChild(form);

      form.submit();
      form.remove();
    },
    copyUrl() {
      let input = document.querySelector("#urlAssets");
      input.setAttribute("type", "text");
      input.select();
      document.execCommand("copy");
      input.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style>
.codemirrorMain .CodeMirror {
  min-height: 450px;
  height: calc(100vh - 76px);
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  transition: 0.9s;
}
.codemirrorMain.alert .CodeMirror {
  height: calc(100vh - 116px) !important;
  transition: 0.01s !important;
}

.url {
  font-size: 14px;
  padding-top: 8px;
  word-break: break-all;
}
.frame {
  border: 0;
  width: 100%;
  min-height: 450px;
  height: calc(100vh - 76px);
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}
</style>
