<template>
  <v-dialog
    v-model="dialog"
    @click:outside="close"
    @keydown.esc="close"
    class="text-center"
    max-width="500px"
  >
    <v-sheet
      class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
      color="blue-grey darken-3"
      dark
    >
      <div class="grey--text text--lighten-1 text-body-3 mb-4">
        Tem certeza que deseja excluir esse arquivo?
      </div>

      <v-btn class="ma-1" color="grey" plain @click="close">Cancelar</v-btn>

      <v-btn class="ma-1" color="error" plain @click="remove"> Excluir </v-btn>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog"],
  data: () => ({}),
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
    remove() {
      this.$emit("remove");
      this.close();
    },
  },
};
</script>

<style>
.qj-codemirror .CodeMirror {
  border: 1px solid #9e9e9e;
  width: 100%;
}
</style>
