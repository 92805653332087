import http from "@/plugins/axios";

export default {
  /**
   * 
   * @returns 
   */
   emails() {
    return http.api.get("v2/emails");
  },
  /**
   * 
   * @param {*} id 
   * @returns 
   */
   export(id) {
    return http.api.get("v2/emails/export/" + id);
  },
}