<template>
  <v-dialog v-model="dialog" max-width="90%" persistent>
    <v-card>
      <v-card-title class="text-h5 primary--text">
        Parâmetros
        <v-spacer></v-spacer>
        <!-- Botão para fechar o modal -->
        <v-btn icon @click="close" style="margin-top: -8px; margin-right: -10px">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        Não é necessário salvar o registro para pré-visualizar
        <codemirror
          class="qj-codemirror"
          :options="cmOptions"
          v-model="code"
        ></codemirror>
        <p class="mb-0 error--text" v-if="error">Não é um JSON válido.</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="setParemeters">Pré-visualizar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog", "editCode", "variables"],
  data: () => ({
    error: false,
    code: "",
    cmOptions: {
      tabSize: 2,
      line: true,
      lineNumbers: true,
      styleActiveLine: true,
      mode: "text/json",
    },
  }),
  watch: {
    dialog() {
      if (this.dialog) setTimeout(() => (this.code = this.buildJson()), 240);
    },
  },
  methods: {
    close() {
      this.code = "{}";
      this.$emit("update:dialog", false);
    },
    setParemeters() {
      this.error = false;
      try {
        JSON.parse(this.code);
      } catch (e) {
        this.error = true;
        return;
      }
      this.$emit("paremeters", this.code);
    },
    buildJson() {
      if (this.editCode != "{}") return this.editCode;
      if (!this.variables.length) return "{}";
      var json = "{";
      this.variables.forEach((variable) => {
        json += '\n\t"' + variable + '": "",';
      });
      json = json.slice(0, -1) + "\n}";
      return json;
    },
  },
};
</script>

<style>
.qj-codemirror .CodeMirror {
  border: 1px solid #9e9e9e;
  height: 60vh;
  width: 100%;
}
</style>
