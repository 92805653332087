<template>
  <v-container fluid>
    <Main
      v-if="showMain"
      :asset.sync="asset"
      @historic="historic"
      @snapshot="searchSnapshots(true)"
    />
    <v-row v-if="showHistoric || showSnapshot">
      <v-col cols="12" md="4" lg="3" xl="2">
        <!-- Histórico -->
        <v-row v-if="showHistoric">
          <v-col cols="12" class="px-0">
            <v-toolbar color="primary" dense flat dark>
              <v-btn icon @click="closeHistoric">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-toolbar-title>Histórico de versões</v-toolbar-title>
              <v-spacer />

              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-swap-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group mandatory color="primary" v-model="tabHistoric">
                    <v-list-item @click="tabHistoric = 0">
                      <v-list-item-title>Código</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="tabHistoric = 1">
                      <v-list-item-title>Preview</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="tabHistoric = 2">
                      <v-list-item-title>Informações</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-toolbar>

            <div v-if="!assetsHistory.length" class="text-center text--disabled pa-4">
              Nenhum histórico encontrado
            </div>

            <v-list class="py-0" v-if="assetsHistory.length">
              <v-list-item-group mandatory v-model="selectedItem" color="primary">
                <template v-for="(item, index) in assetsHistory">
                  <v-list-item
                    :key="item.id"
                    @click="setHistoric(item.id)"
                    :disabled="item.id == assetsHistory[0].id"
                  >
                    <v-list-item-avatar>
                      <!-- <v-img :src="item.user.profile_photo_url"></v-img> -->
                      <img
                        :alt="item.user.nome"
                        :src="item.user.profile_photo_url"
                        v-if="item.user.profile_photo_url"
                      />
                      <v-icon class="primary" dark v-else> mdi-account-circle </v-icon>
                      <!-- <v-icon color="white">mdi-account-circle</v-icon> -->
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.user.name"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.updated_at | formatDate }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="index" :inset="true"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
        <!-- Snapshot -->
        <v-row v-else-if="showSnapshot">
          <v-col cols="12" class="px-0 pb-0">
            <v-toolbar color="primary" dense flat dark>
              <v-btn icon @click="closeSnapshot">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-toolbar-title>Snapshots</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-text-field
              hide-details
              placeholder="Pesquisar"
              class="rounded-0 pa-0 ma-0"
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              @keyup.enter="snapshotsFiltered()"
            ></v-text-field>
            <v-data-table
              dense
              fixed-header
              hide-default-header
              disable-items-per-page
              locale="pt-br"
              height="calc(100vh - 201px)"
              :items="snapshots"
              :mobile-breakpoint="0"
              :loading="loadingTable"
              :options.sync="options"
              :server-items-length="pagination.total"
              :footer-props="{
                'items-per-page-options': [30],
                'disable-items-per-page': true,
              }"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="loadingTable">
                  <tr>
                    <td class="text-center py-4">Carregando...</td>
                  </tr>
                </tbody>
                <tbody v-else-if="items.length">
                  <tr
                    style="cursor: pointer"
                    :key="index"
                    @click="setSnapshot(item.id, index)"
                    v-for="(item, index) in items"
                    :class="selectedSnapshots == index ? 'blue lighten-5' : ''"
                  >
                    <td class="py-2">
                      <p
                        class="ma-0"
                        :class="selectedSnapshots == index ? 'primary--text' : ''"
                      >
                        {{ item.external_ref }}
                      </p>
                      <span class="grey--text">{{ item.created_at | formatDate }}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center py-4">Nenhum snapshot encontrado</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" lg="9" xl="10" class="px-0 pb-0">
        <!-- Histórico -->
        <v-row class="text-center grey lighten-2 ma-0 history-header" v-if="showHistoric">
          <v-col class="pa-0">ANTERIOR</v-col>
          <div style="width: 6%"></div>
          <v-col class="pa-0">ATUAL</v-col>
        </v-row>
        <v-tabs-items v-model="tabHistoric" v-if="showHistoric && assetsHistory.length">
          <!-- Cod -->
          <v-tab-item>
            <codemirror
              ref="codemirrorMergeHTML"
              class="codemirrorMergeHTML"
              :merge="true"
              :options="cmOptionsComparison"
            ></codemirror>
          </v-tab-item>
          <!-- Preview -->
          <v-tab-item style="height: calc(100vh - 124px)">
            <iframe
              style="
                border: 0;
                width: 50%;
                min-height: 450px;
                height: calc(100vh - 124px);
                border-left: 1px solid #dddddd;
              "
              :srcdoc="codeHTMLComparison"
            ></iframe>
            <iframe
              style="
                border: 0;
                width: 50%;
                min-height: 450px;
                height: calc(100vh - 124px);
                border-left: 1px solid #dddddd;
              "
              :srcdoc="asset.content"
            ></iframe>
          </v-tab-item>
          <!-- Info -->
          <v-tab-item>
            <v-row class="pa-3">
              <v-col
                cols="6"
                style="
                  border-left: 1px solid rgb(221, 221, 221);
                  height: calc(100vh - 124px);
                "
              >
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <p class="ma-0 overline grey--text text--darken-2">Caminho</p>
                    <p>{{ pathComparison || "-" }}</p>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <p class="ma-0 overline grey--text text--darken-2">Tipo do arquivo</p>
                    <p>{{ typeComparison || "-" }}</p>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <p class="ma-0 overline grey--text text--darken-2">Parâmetros</p>
                    <pre>{{
                      codeJSONComparison ? JSON.parse(codeJSONComparison) : "-"
                    }}</pre>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="6"
                style="
                  border-left: 1px solid rgb(221, 221, 221);
                  height: calc(100vh - 124px);
                "
              >
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <p class="ma-0 overline grey--text text--darken-2">Caminho</p>
                    <p>{{ asset.path }}</p>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <p class="ma-0 overline grey--text text--darken-2">Tipo do arquivo</p>
                    <p>{{ asset.type }}</p>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <p class="ma-0 overline grey--text text--darken-2">Parâmetros</p>
                    <pre>{{ asset.params ? JSON.parse(asset.params) : "-" }}</pre>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <div
          v-else-if="showHistoric && !assetsHistory.length"
          style="
            border: 0;
            width: 100%;
            min-height: 450px;
            height: calc(100vh - 100px);
            border-left: 1px solid #dddddd;
          "
          class="d-flex align-center justify-center qj-loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :size="70"
          ></v-progress-circular>
        </div>
        <!-- Snapshot -->
        <div
          v-if="showSnapshot"
          style="
            border: 0;
            width: 100%;
            min-height: 450px;
            height: calc(100vh - 76px);
            border-top: 1px solid #dddddd;
            border-left: 1px solid #dddddd;
          "
          class="d-flex align-center justify-center qj-loading"
        >
          <v-progress-circular
            v-if="loadingSnapshot"
            indeterminate
            color="primary"
            :size="70"
          ></v-progress-circular>
          <iframe
            v-else-if="snapshot"
            style="width: 100%; height: 100%; border: 0"
            :src="snapshot"
          ></iframe>
          <div v-else>Não há snapshot!</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import { bus } from "../../../main";
import assets from "@/services/assets";
import snapshots from "@/services/snapshots";
import { mapActions, mapGetters } from "vuex";

import Main from "../components/frame/Main";

export default {
  components: {
    Main,
  },
  filters: {
    formatDate(date) {
      var d = new Date(date);
      d.setTime(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
      return d.toLocaleString("pt-BR");
    },
  },
  data: () => ({
    id: undefined,
    asset: null,
    snapshot: null,
    dialog: false,
    loading: false,
    showMain: false,
    showHistoric: false,
    showSnapshot: false,
    loadingTable: false,
    loadingSnapshot: false,
    tabHistoric: 0,
    selectedItem: 1,
    selectedSnapshots: 0,
    search: "",
    pathComparison: "",
    typeComparison: "",
    codeJSONComparison: "",
    codeHTMLComparison: "",
    previewData: "{}",
    snapshots: [],
    variables: [],
    assetsHistory: [],
    cmOptionsComparison: {
      connect: "align",
      mode: "text/html",
      readOnly: true,
      lineNumbers: true,
      revertButtons: false,
      collapseIdentical: false,
      highlightDifferences: true,
    },
    options: {
      page: 1,
    },
    pagination: {
      total: 0,
    },
  }),
  async created() {
    this.isMobile();
    window.addEventListener("resize", this.isMobile);

    this.$route.meta.showBtns = true;
    if (this.$route.params.id != "new") {
      this.id = this.$route.params.id;

      this.asset = store.getters["home/getAssets"].find((i) => i.id == this.id);
      if (typeof this.asset === "undefined") {
        await assets.asset(this.id).then((resp) => (this.asset = resp.data));
      }

      bus.$emit("title", this.asset.path);
      this.previewData = this.asset.preview_params || "{}";

      if (this.$route.params.page == "historic") {
        this.$route.meta.showBtns = false;
        this.historic(false);
      } else if (this.$route.params.page == "snapshot") {
        this.$route.meta.showBtns = false;
        this.searchSnapshots(false);
      } else {
        this.show();
      }
    } else {
      bus.$emit("title", "Novo arquivo");
      this.showMain = true;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobile);
    document.getElementsByTagName("HTML")[0].style.overflow = "visible";
  },
  computed: {
    ...mapGetters("auth", { me: "getMe" }),
  },
  watch: {
    tabHistoric() {
      if (this.tabHistoric == 0) {
        if (this.assetsHistory.length) {
          setTimeout(() => {
            if (typeof this.$refs.codemirrorMergeHTML === "undefined") return;

            var asset = this.assetsHistory[this.selectedItem];
            this.$refs.codemirrorMergeHTML.codemirror.edit.setValue(asset.content);
            setTimeout(() => {
              this.$refs.codemirrorMergeHTML.codemirror.edit.setValue(asset.content);
            }, 250);
          });
        }
      }
    },
    asset() {
      this.assetsHistory = [];
    },
    options(newOptions, oldOptions) {
      if (this.showSnapshot && newOptions.page != oldOptions.page) this.getSnapshots();
    },
  },
  methods: {
    ...mapActions("home", ["ActionsReset", "ActionsSetAssets"]),
    show(frame = "Main") {
      this.showMain = false;
      this.showHistoric = false;
      this.showSnapshot = false;
      this["show" + frame] = true;
    },
    historic(setRoute = true) {
      if (!this.assetsHistory.length) {
        assets
          .assetsHistory(this.id)
          .then((resp) => {
            if (setRoute) this.setRoute("historic");

            if (typeof this.$route.query.item === "undefined") {
              setTimeout(() =>
                this.$router.replace({ query: { item: this.selectedItem } })
              );
            } else {
              this.selectedItem = parseInt(this.$route.query.item);
            }

            this.assetsHistory = resp.data.objects.sort((a, b) => {
              if (new Date(a.created_at) > new Date(b.created_at)) return -1;
              if (new Date(a.created_at) < new Date(b.created_at)) return 1;
              return 0;
            });
            this.cmOptionsComparison.orig = this.asset.content;

            if (this.assetsHistory.length > 1) {
              this.cmOptionsComparison.value = this.assetsHistory[
                this.selectedItem
              ].content;

              this.codeHTMLComparison = this.assetsHistory[this.selectedItem].content;

              this.pathComparison = this.assetsHistory[this.selectedItem].path;
              this.typeComparison = this.assetsHistory[this.selectedItem].type;
              this.codeJSONComparison = this.assetsHistory[this.selectedItem].params;
            } else {
              this.cmOptionsComparison.value = this.asset.content;
            }
          })
          .finally(() => this.show("Historic"));
      } else {
        if (setRoute) this.setRoute("historic");

        if (typeof this.$route.query.item === "undefined") {
          setTimeout(() => this.$router.replace({ query: { item: this.selectedItem } }));
        } else {
          this.selectedItem = parseInt(this.$route.query.item);
        }

        this.show("Historic");
      }
    },
    searchSnapshots(setRoute) {
      if (setRoute) this.setRoute("snapshot");
      this.show("Snapshot");

      if (typeof this.$route.query.item !== "undefined") {
        this.selectedSnapshots = parseInt(this.$route.query.item);
      }
      if (typeof this.$route.query.page !== "undefined") {
        this.options.page = parseInt(this.$route.query.page);
      }
      if (typeof this.$route.query.search !== "undefined") {
        this.search = this.$route.query.search;
      }

      setTimeout(() => this.setQuery());

      if (!this.snapshots.length) {
        this.getSnapshots();
      } else {
        this.loadingSnapshot = true;
        snapshots
          .single(this.snapshots[this.selectedSnapshots].id)
          .then((resp) => {
            this.snapshot = resp.data.data.url;
          })
          .finally(() => (this.loadingSnapshot = false));
      }
    },
    closeHistoric() {
      this.setRoute();
      this.selectedItem = 1;
      this.tabHistoric = 0;
      this.show();
    },
    closeSnapshot() {
      this.setRoute();
      this.show();
    },
    getSnapshots() {
      this.loadingTable = true;
      snapshots
        .all({
          limit: "30",
          asset_id: this.id,
          order: "-created_at",
          page: this.options.page,
          external_ref: this.search,
        })
        .then((resp) => {
          this.snapshots = resp.data.objects;
          this.pagination.total = resp.data.num_results;

          if (resp.data.num_results > 0) {
            if (typeof this.$route.query.search !== "undefined") {
              this.search = this.$route.query.search;
            }

            if (typeof this.$route.query.item === "undefined") {
              this.$router.replace({
                query: { item: this.selectedSnapshots },
              });
            } else {
              this.selectedSnapshots = parseInt(this.$route.query.item);
            }

            if (typeof this.$route.query.page === "undefined") {
              this.$router.replace({
                query: { page: this.options.page },
              });
            }
            if (this.snapshots.length && this.$route.query.page == this.options.page) {
              if (
                this.snapshot &&
                (typeof this.$route.query.search === "undefined" ||
                  this.$route.query.search == this.search) &&
                this.$route.query.page == this.options.page &&
                (this.selectedSnapshots == -1 ||
                  this.$route.query.item == this.selectedSnapshots)
              )
                return;
              this.loadingSnapshot = true;
              snapshots
                .single(this.snapshots[this.selectedSnapshots].id)
                .then((resp) => {
                  this.snapshot = resp.data.data.url;
                })
                .finally(() => (this.loadingSnapshot = false));
            } else this.selectedSnapshots = -1;
          } else this.loadingSnapshot = false;
        })
        .finally(() => (this.loadingTable = false));
    },
    setHistoric(id) {
      setTimeout(() => {
        if (this.$route.query.item != this.selectedItem) {
          this.$router.replace({ query: { item: this.selectedItem } });
        }
      }, 100);

      let asset = this.assetsHistory.find((f) => f.id == id);

      this.$refs.codemirrorMergeHTML.codemirror.edit.setValue(asset.content);

      this.codeHTMLComparison = asset.content;

      this.pathComparison = asset.path;
      this.typeComparison = asset.type;
      this.codeJSONComparison = asset.params;
    },
    setSnapshot(id, index) {
      if (
        this.selectedSnapshots != index ||
        this.$route.query.page != this.options.page
      ) {
        this.loadingSnapshot = true;
        this.selectedSnapshots = index;
        snapshots
          .single(id)
          .then((resp) => {
            this.snapshot = resp.data.data.url;
            if (
              this.$route.query.item != this.selectedSnapshots ||
              this.$route.query.page != this.options.page
            ) {
              let newQuery = { ...this.$route.query };
              newQuery.item = this.selectedSnapshots;
              newQuery.page = this.options.page;
              this.$router.replace({ query: newQuery });
            }
          })
          .finally(() => (this.loadingSnapshot = false));
      }
    },
    setRoute(page = "") {
      if (page != "") {
        page = "/" + page;
        this.$route.meta.showBtns = false;
      } else {
        this.$route.meta.showBtns = true;
      }
      this.$router.replace({
        path: "/asset/" + this.$route.params.id + page,
      });
    },
    snapshotsFiltered() {
      this.snapshot = null;
      this.options.page = 1;
      this.selectedSnapshots = 0;
      this.setQuery();
      this.getSnapshots();
    },
    setQuery() {
      var query = {
        item: this.selectedSnapshots + "",
        page: this.options.page + "",
      };

      if (this.search) query.search = this.search;

      if (JSON.stringify(query) !== JSON.stringify(this.$route.query))
        this.$router.replace({ query: query });
    },
    isMobile() {
      let overflow = "visible";
      if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs)
        overflow = "hidden";
      document.getElementsByTagName("HTML")[0].style.overflow = overflow;
    },
  },
};
</script>

<style large="scss">
.codemirrorJSON .CodeMirror {
  height: calc(100vh - 394px);
  border: 1px solid #9e9e9e;
  min-height: 132px;
}

.codemirrorMergeHTML .CodeMirror-merge {
  border: 0;
  border-left: 1px solid #dddddd;
  min-height: 450px;
  height: calc(100vh - 124px);
}
.codemirrorMergeHTML .CodeMirror {
  min-height: 450px;
  height: calc(100vh - 124px);
}

@media (max-width: 959px) {
  .codemirrorJSON .CodeMirror {
    max-height: 132px;
  }
}

.history-header {
  align-content: center;
  height: 48px;
}

.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 0 !important;
  padding: 4px 8px;
  justify-content: end;
}
</style>
